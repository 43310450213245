  
.grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}

.grid path {
    stroke-width: 0;
}

.grid text {
    color: transparent;
}