@import '../../styles/theme.less';
.pieContainer {
  display: flex;
}

.pie {
  flex:1;
  display:flex;
  justify-content: flex-end;
  margin-right: 12px;
  align-items: center;
}

.pieText {
  flex: 1.3;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.primary {
  color: @primary-color;
}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;