@import '../../../styles/theme.less';
.container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  margin-top: 4px;
}
.menu {
  min-width: 124px;
  border-left: 1px solid @normal-bg;
}

.menu :global(.ant-menu-item) {
  margin-bottom: 0;
  height: 32px;
  line-height: 32px;
}

.menuItem {
  font-size: 12px;
}

.horizon {
  display: flex;
}

.bottom {
  border-top: 1px solid @normal-bg;
  justify-content: space-between;
  padding: 8px 12px;
  display: flex;
  flex: 1;
  background: #fff;
}
@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;