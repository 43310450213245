@import '../../../styles/theme.less';

.editableCellWrap {
  padding: 5px 12px;
  cursor: pointer;
  word-break: break-all;
  // css ellipsis
  //overflow: hidden;
  //text-overflow: ellipsis;
  //display: -webkit-box;
  //-webkit-line-clamp: 3;
  //-webkit-box-orient: vertical;
}

.editableRow:hover .editableCellWrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.tableHeader {
  color: @card-title !important;
  text-transform: capitalize;
}

.tableContainer {
  :global(.ant-table) {
    border-radius: 8px;
  }
  :global(.ant-table-content) {
    border-radius: 8px;
  }

}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;