@import '~react-grid-layout/css/styles.css';
// @import './components/Dashboard/index.css';
@import 'styles/theme';
@import '~react-date-range/dist/styles.css'; // main style file
@import '~react-date-range/dist/theme/default.css'; // theme css file

.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-layout {
  min-height: 100vh;
}

.site-layout .site-layout-background {
  background: #fff;
}

#icon-lg-color1 {
  stop-color: @primary-color;
}

#icon-lg-color2 {
  stop-color: @primary-color-1;
}

.ant-popover-inner-content {
  padding: 0 20px;
}

.ant-popover-inner-content {
  padding: 0 20px;
}

h4.ant-typography {
  color: @text-color;
}

.react-resizable-handle {
  visibility: hidden;
}

.react-grid-item:hover .react-resizable-handle {
  visibility: visible;
}

.leaflet-container {
  width: 100%;
  margin: 0 auto;
}

.eb-text-card-title {
  color: @card-title;
  font-weight: 600;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.ant-layout-footer .ant-divider-vertical {
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}

.ant-page-header-heading-title {
  font-size: 14px;
  font-family: 'Open Sans';
  font-variant: tabular-nums;
  line-height: 1.57;
  color: #767676
}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;