@import './theme.less';

.logo {
  height: 64px;
  background: rgba(255, 255, 255);
  padding: 12px;
}

.side {
  background-color: @primary-color-1 !important;
  background-image: linear-gradient(@primary-color 0%, @primary-color-1 600px) !important;
  box-shadow: rgba(0, 0, 0, 0.2) -18px -18px 18px 10px;
  :global(.ant-menu-item-selected) {
    background: fade(darken(@primary-color-1, 10%), 80%) !important;
  }
  :global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
    background: transparent;
  }
}

.popup {
  background-color: @tool-tips-bg !important;
  :global(.ant-menu-item-selected) {
    background: fade(@primary-color, 80%) !important;
  }
}

.menu {
  :global(.ant-menu-inline-collapsed) {
    width: 64px;
  }
  :global(.ant-menu-inline.ant-menu-sub) {
    background: transparent;
  }
  :global(.ant-menu-submenu-title) {
    font-size: 1rem;
  }
  :global(.ant-menu-item) {
    font-size: 1rem;
  }
}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;