.entered {
    stroke: black;
    stroke-width: 2;
}
.userchunk-cover {
    pointer-events: all;
}

.block.block-hovered {
    stroke: black;
    stroke-width: 2;
    cursor: pointer;
}

