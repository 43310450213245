@import '../styles/theme.less';

.chartCardContainer {
  padding: 18px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
}

.rightTopContainer {
  position: absolute;
  top: 18px;
  right: 18px;
}

.chartCardTitle {
  text-transform: capitalize;
  flex: 1;
}

.chartCardHeaderRow {
  display: flex;
  margin-bottom: 8px;
}

.tipsIcon {
  color: @primary-color-1;
}

.menu {
  margin-top: 2px;
}

.chart {
  flex: 1;
}

.iconExpansion:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;