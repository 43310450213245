@import '../../../styles/theme.less';

.background {
  // :global(.ant-layout) {
  //   min-height: calc(100vh);
  //   background: transparent;
  // }
  background-image: url('../../../assets/login_bg.png');
  min-height: 100vh;
}

.footer {
  background: linear-gradient(fade(@normal-bg, 20), @normal-bg);
  text-align: center;
}

.container {

  //padding-top: calc(min(50vh, 400px) - 250px);
}

.left {
  background: linear-gradient(@primary-color, @primary-color-1);
  padding: 48px;
}

.top {
  background: linear-gradient(@primary-color, @primary-color-1);
  //padding: 0 24px;
  max-width: 450px;
  margin: 0 auto;
}

.leftImage {
  width: 100%;
}

.logo {
  padding: 0 24px;
  composes: leftImage;
}

.right {
  background: #fff;
  max-width: 450px;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

h3.loginTitle {
  color: @text-color;
  font-weight: normal;
}

.formContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgetPwd {
  text-align: center;
}

.loginContainer {
  display: flex;
  justify-content: space-between;
}

.loginBtn {
  min-width: 100px;
}

.languageBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px;
}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;