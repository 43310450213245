.rightBarIcon {
  padding: 16px 0px 12px 0px;
  margin: auto;
}

.rightBarContainer {
  min-height: calc(100vh - 64px);
  background: #fff;
  :global(.ant-divider-horizontal) {
    margin: 4px 0;
  }
  border-left: #efefef solid 1px;
}

.containerPadding {
  padding: 0 16px;
}

.optionsContainer {
  padding: 20px 16px;
}

.collapsedText {
  display: flex;
  justify-content: center;
}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;