.leaflet-tile-container img {
    width: 256.5px !important;
    height: 256.5px !important;
}

.piechart-tooltip{
    position: absolute;
    right: 4px;
    top: 1.5rem;
}
.map-legend-container {
    position: absolute;
    bottom: 0rem;
    left: 1rem;
    z-index: 100000;
}

rect.tile-legend {
    opacity: 1;
}