.table {
  padding: 0 8px;
  :global(.ant-table-small .ant-table-thead > tr > th) {
    background-color: #fff !important;
    padding: 0px 4px 8px 0;
  }
  :global(.ant-table.ant-table-small .ant-table-tbody > tr > td) {
    background-color: #fff !important;
    padding: 8px 4px 8px 0;
  }
  :global(.ant-table.ant-table-small .ant-table-footer) {
    background: #fff;
    padding: 0;
    border-top: 1.5px solid #f0f0f0;
  }
}

.tableCell {
  padding: 4px 4px 4px 0;
  flex:1;
  text-align: center;
}

@primary-color: #1FA9DD;@link-color: #1FA9DD;@font-size-base: 14px;@text-color: #2D2D2D;@primary-3: #6B74C7;@primary-4: #384295;@slider-handle-color-focus: #6B74C7;@slider-handle-color-tooltip-open: #384295;@menu-dark-bg: transparent;@menu-dark-submenu-bg: transparent;@font-family: 'Open Sans', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #767676;@tabs-horizontal-margin: 0;@tabs-horizontal-padding: 12px 18px;