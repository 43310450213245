.chart-container .center-tip {
    text-anchor: middle;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 12px;
}

.slice-notation {

    text-anchor: middle;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 12px;
}

path.pie {
    cursor: pointer;;
    stroke: black;
    stroke-width: 0px;
}
