.tooltip-container {
    position: absolute;
    font-size: 13px;
    font-family: inherit;
    width: 200px;
    background-color: #fafafa;
    padding: 4px;
    box-shadow: 2px 3px 5px 0px #d7d7d7;
    border-radius: 5px;
    pointer-events: none;
    z-index: 100;
}

.tooltip-header {
}

.tooltip-content {
    font-size: 11px;
    font-family: inherit;
}